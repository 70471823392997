import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowHeader from '../components/organisms/officialLowHeader'

const Wrapper = styled.main`
  padding: 0 0 80px;
  .p-section-inner {
    max-width: 1080px;
    margin: 0 auto;
    padding: 40px 40px 0;
    h3 {
      margin: 40px 0 20px;
    }
    p {
      margin: 0 0 20px;
    }
    ul,
    ol {
      margin: 5px 0 20px;
      li {
        margin-bottom: 5px;
        text-indent: -1.15em;
        margin-left: 30px;
      }
    }
    ul {
      li {
        list-style: disc;
        display: list-item;
        list-style-position: inside;
        ul {
          li {
            list-style: circle;
            list-style-position: inside;
            margin-left: 30px;
            ul {
              li {
                list-style: square;
                list-style-position: inside;
              }
            }
          }
        }
      }
    }
    ol {
      li {
        list-style: decimal;
        display: list-item;
        list-style-position: inside;
        ol {
          li {
            margin-left: 30px;
          }
        }
        ul {
          li {
            list-style: disc;
            list-style-position: inside;
          }
        }
      }
      &.parent {
        li {
          list-style-type: none;
          counter-increment: cnt;
          &:before {
            content: '(' counter(cnt) ') ';
          }
        }
      }
    }
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 特定商取引法に基づく表記[サービスサイト特定商取引法に基づく表記]
 * @constructor
 */
const LawPage = ({ location }: IProps) => (
  <OfficialLayout location={location}>
    <SEO title="特定商取引法に基づく表記" />
    <Wrapper>
      <OfficialLowHeader
        label="特定商取引法に基づく表記"
        description="キンクラ"
      />

      {/** 第1条 */}
      <div className="p-section-inner">
        <h3>販売事業者の名称</h3>
        <p>CLINKS株式会社</p>
        <h3>販売責任者</h3>
        <p>
          代表取締役社長
          <br />
          河原浩介
        </p>
        <h3>所在地住所</h3>
        <p>
          〒104-0032
          <br />
          東京都中央区八丁堀1-10-7
          <br />
          TMG八丁堀ビル10F
        </p>
        <h3>お問い合わせ窓口</h3>
        <ul>
          <li>
            電話でのお問い合わせ
            <br />
            03-6262-8135
            <br />
            受付時間：月～金 11:00～18:00
            <br />
            (土・日・祝日および弊社指定休日を除く)
          </li>
          <li>
            メールでのお問い合わせ
            <br />
            cl-info@clinks.jp
          </li>
        </ul>
        <h3>販売価格</h3>
        <p>
          <a href="/plan-list" target="_blank">
            料金ページ
          </a>
          をご参照ください
        </p>
        <h3>販売価格以外に発生する料金</h3>
        <p>
          料金の表示価格には消費税が含まれています。
          サービスを利用するために必要となるインターネット接続料金、通信料金等はお客様の負担となります。
          銀行振込の振込手数料はお客様の負担となります。
        </p>
        <h3>お支払い方法</h3>
        <p>
          クレジットカード決済
          <br />
          ※Enterpriseプランの場合は請求書払いに対応しています。
        </p>
        <h3>引渡し時期</h3>
        <p>お支払い完了後､直ちにご利用いただけます｡</p>
        <h3>不良品について</h3>
        <p>
          商品不良や不具合が発見された場合は対処方法をサービス内にてご案内いたします。
        </p>
        <h3>キャンセルについて</h3>
        <p>
          いつでも解約手続を行うことができますが、予定された利用期間の途中において解約手続を行った場合でも、お支払い済みの料金は返金されません。
        </p>
      </div>

      {/** 制定日 */}
      <div className="p-section-inner">
        <p>2021年10月27日制定</p>
      </div>
    </Wrapper>
  </OfficialLayout>
)

export default LawPage

export const pageQuery = graphql`
  query LawPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
